import React from "react"

const NewsletterSub = () => {
  return (
    <div className="flex justify-center">
      <iframe
        title="Melodie Newsletter subscription"
        src="https://menghan.substack.com/embed"
        width="480"
        height="320"
        //   style="border:1px solid #EEE; background:white;"
        //   frameborder="0"
        scrolling="no"
      />
    </div>
  )
}

export default NewsletterSub
